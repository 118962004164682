(function() {
    angular.module('EntrakV5').controller('tenantController', tenantController);

    function tenantController($scope, $rootScope, $state, Service, KEY, APIKEY, Api, $stateParams, $anchorScroll, $timeout) {
        console.log('tenantController');

        //$stateParams.tenantId
        var caller = Api.createApiCaller();
        $scope.btnStatus = {};

        $scope.timezones = [];
        $scope.tenants = [];
        $scope.buildings = [];
        $scope.zones = [];
        $scope.distributorId = null;
        $scope.distributorName = null;

        $scope.tenantIndexLetter = [];
        $scope.searchText = '';

        $scope.tenantLbl = Service.translate("tenant.tenantSearch");

    /* first load */
        $rootScope.loadingPage = 0;
        $scope.loadPage = function(callback){
            $rootScope.loadingPage++;
            $scope.tenants = [];
            $scope.tenantIndexLetter = [];
            caller.call([Api.getLandlordBuildings(), Api.getTenants(false, false), Api.getCities()]).then(function(res){
                var nameSorter = Service.getSorterIgnoreCase();
                var buildingMap = Service.arrayToMap(res[0].buildings);
                $scope.buildings = res[0].buildings;
                $scope.buildings.sort(nameSorter);
                $scope.buildings.forEach(function(b) {
                    b.buildingId = b.id;
                });
                $scope.buildingDropdown.setDataSource(new kendo.data.DataSource({
                    data: $scope.buildings
                }));
                $scope.buildingDropdown2.setDataSource(new kendo.data.DataSource({
                    data: $scope.buildings
                }));
                $scope.buildingDropdown3.setDataSource(new kendo.data.DataSource({
                    data: $scope.buildings
                }));
                $scope.zones = $scope.buildings.reduce(function(arr, b){
                    return arr.concat(b.zones);
                }, []);
                $scope.zoneDropdown.setDataSource(new kendo.data.DataSource({
                    data: $scope.zones
                }));

                $scope.tenantIndexLetter = [];
                res[1].forEach(function(t) {
                    t.searchName = t.name.toLowerCase();
                    t.locations = [];
                    if (t.zones && t.zones.length){
                        var zMap = {};
                        t.zones.sort(nameSorter);
                        t.zones.forEach(function(z) {
                            if (!zMap[z.buildingId]) {
                                zMap[z.buildingId] = [];
                            }
                            zMap[z.buildingId].push(z);
                        });
                        t.locations = Object.keys(zMap).map(function(key){
                            return {
                                building: buildingMap[key],
                                zones: zMap[key]
                            }
                        });
                        t.locations.sort(nameSorter);
                    }
                });
                res[1].sort(Service.getSorter("searchName"));

                res[1].forEach(function(t) {
                    var firstLetter = t.searchName.charAt(0);
                    if ($scope.tenantIndexLetter.length) {
                      if ($scope.tenantIndexLetter.slice(-1)[0] != firstLetter)
                        $scope.tenantIndexLetter.push(firstLetter);
                    } else {
                      $scope.tenantIndexLetter.push(firstLetter);
                    }
                });

                var tzList = Object.keys(Service.arrayToMap(res[2], "timezone"));
                tzList.sort((a, b) => a.localeCompare(b));
                $scope.timezones = tzList;
                $scope.tzDropdown1.setDataSource(new kendo.data.DataSource({
                    data: tzList
                }));
                $scope.tzDropdown2.setDataSource(new kendo.data.DataSource({
                    data: tzList
                }));

                // tenants: [{ id, name, locations: [{ building, zones}] }]
                $scope.tenants = res[1];
                $scope.distributorId = res[0].id;
                $scope.distributorName = res[0].name;
                $rootScope.loadingPage--;
                if (typeof callback === 'function')
                    callback();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
        }
        $scope.$watch('showType', function(n, o){
          if (n && n !== o) {
            $scope.loadPage();
          } else {//first load
            $scope.loadPage($scope.gotoTenant);
          }
        });

        $scope.isVisibleTenant = function(t){
          var search = $scope.searchText.trim().toLowerCase();
          return !search || t.searchName.indexOf(search) != -1;
        }

        $scope.gotoTenant = function(indexLetter) {
          var id = null;
          if (indexLetter) {
            var tenant = $scope.tenants.find(t => t.searchName.charAt(0) === indexLetter && $scope.isVisibleTenant(t));
            if (tenant)
              id = tenant.id;
          } else if ($stateParams.tenantId){
            if ($scope.tenants.find(t => t.id === $stateParams.tenantId)) {
              id = $stateParams.tenantId;  
            } else {
              $state.transitionTo('tenant', null, {
                notify: false,
                replace: true
              });
            }
          }
          if (id) {
            $timeout(function(){
              $anchorScroll.yOffset = 60;
              $anchorScroll(id);
            }, 100);
          }
        }

        $scope.clickIndex = function($event) {
          $scope.gotoTenant(angular.element($event.target).attr("id"));
        }

        $scope.parseDomain = function(domain){
          domain = (domain || "").trim();
          if (domain.substr(0, 1) === "@")
            domain = domain.substr(1);
          return domain.toLowerCase();
        }
        $scope.isValidDomain = function(domain){
            var domainReg = /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            domain = (domain || "").trim();
            if (domain.substr(0, 1) === "@")
                domain = domain.substr(1);
            return domain.indexOf("@") == -1 && domainReg.test(domain);
        }

        $scope.editTenantAdmin = function(tenant){
            $state.go("tenantAdmin", { tenantId: tenant.id });
        }
    /* first load */

    /* floor */
        $scope.floorData = {
            callback: function(){
                setTimeout(function(){
                    $scope.editFloorWin.center();
                });
            }
        }
        $scope.editFloorWinOpt = {
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.gatewaySelectOpt = {
            autoWidth: true,
            autoClose: false,
            clearButton: false,
            dataSource: [],
            dataTextField: "serialId",
            dataValueField: "id"
        }
        $scope.buildingDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "id",
            change: function(){
                var building = Service.getArrItem($scope.buildings, this.value());
                updateGwSelect(building);
            }
        }
        $scope.tzDropdownOpt = {
            autoWidth: true,
            dataSource: [],
        }
        function updateGwSelect(building){
            $scope.gatewaySelect.setDataSource(new kendo.data.DataSource({
                data: (building ? building.hubs.reduce(function(arr, hub){
                    return arr.concat(hub.gateways);
                }, []) : [])
            }));
        }

        $scope.addFloor = function(tenant){
            if ($scope.buildings.length) {
                $scope.floorData.buildingId = $scope.buildings[0].id;
                updateGwSelect($scope.buildings[0]);    
            } else {
                $scope.floorData.buildingId = null;
                updateGwSelect(null);
            }
            $scope.floorData.init();
            $scope.floorData.tenant = tenant;
            $scope.floorData.name = '';
            $scope.floorData.gatewayIds = [];
            $scope.editFloorWin.title(Service.translate("tenant.addFloor"));
            setTimeout(function(){
                $scope.editFloorWin.open().center();
            });
        }
        $scope.confirmAddFloor = function(){
            $scope.btnStatus.saving = true;
            var tenant = $scope.floorData.tenant;
            caller.call(Api.createFloor($scope.floorData.buildingId, tenant.dns, $scope.floorData.name, $scope.floorData.fileObject, $scope.floorData.gatewayIds, $scope.floorData.alwaysOn)).then(function(res){
                $scope.btnStatus.saving = false;
                $scope.loadPage();
                $scope.editFloorWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                alert(Service.translate("error.generalCreateFloor"));
                $scope.btnStatus.saving = false;
            });
        }

        $scope.selectFloor = function(floor, tenant){
            if (floor && tenant){
                $rootScope.infoMap[floor.id] = { name: floor.name };
                $rootScope.infoMap[tenant.id] = { name: tenant.name, dns: tenant.dns };
                $state.go("floor", { floorId: floor.id, tenantId: tenant.id });
            }
        }
    /* floor */

    /* exportDeviceActivity */
        $scope.exportDeviceData = {}
        $scope.exportDeviceWinOpt = {
          title: Service.translate("tenant.exportDeviceActivity"),
          width: "640px",
          modal: true,
          draggable: false,
          visible: false,
          resizable: false,
          open: function(){
              $scope.$apply(function(){
                  $scope.btnStatus.saving = false;
              });
          }
        }
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        function updateRange(){
          now = new Date();
          now.setHours(0, 0, 0, 0);
          $scope.startPicker.setOptions({max: now});
        }
        $scope.pickerStartOpt = {
          max: now,
          open: updateRange,
          change: function(){
            $timeout(function(){
              var start = $scope.exportDeviceData.start.getTime();
              var endMax = new Date(Math.min(start + 29*24*3600000, now.getTime()));// 30 days, will add 1 day to endDate when call api
              if ($scope.exportDeviceData.end) {
                var end = $scope.exportDeviceData.end.getTime();
                if (start > end){
                  $scope.exportDeviceData.end = new Date(start);
                } else if (end - start > 29*24*3600000) {
                  $scope.exportDeviceData.end = endMax;
                }
              }
              $scope.endPicker.min(new Date(start));
              $scope.endPicker.max(endMax);
            }, 100);
          },
        }
        $scope.pickerEndOpt = {
          max: now,
        }

        $scope.exportDeviceActivity = function(floor) {
          $scope.exportDeviceData.name = floor.name;
          $scope.exportDeviceData.id = floor.id;
          $scope.exportDeviceData.end = new Date(now);
          $scope.exportDeviceData.start = new Date(now);
          $timeout(function(){
              $scope.exportDeviceWin.open().center();
          });
          $timeout(function(){
              $scope.startPicker.trigger("change");
          }, 50);
        }
        $scope.confirmExportDeviceData = function(){
          $rootScope.loadingPage++;
          $scope.btnStatus.saving = true;
          var displayStart = Service.getUnixTimestamp($scope.exportDeviceData.start);
          var start = displayStart - 24 * 3600;
          var end = Service.getUnixTimestamp($scope.exportDeviceData.end) + 24 * 3600;
          var logDurationInDay = (end - displayStart) / 24 / 3600;
          var name = $scope.exportDeviceData.name;
          caller.call([Api.getDevicesOnlineLogsByZone($scope.exportDeviceData.id, start, end), Api.getDevicesActivitiesByZone($scope.exportDeviceData.id, start, end)]).then(function(res){
            var map = Service.arrayToMap(res[0], "deviceId");
            //assume onOffline device number >= onOff device number (device must have onOffline but maybe no onOff function)?
            //no this assumption now, e.g. if server down, the daily record now be missing
            var activityOnlyMap = {};
            res[1].forEach(r => {
                if (map[r.deviceId]) {
                  map[r.deviceId].deviceActivity = r.deviceActivity;
                } else {
                    activityOnlyMap[r.deviceId] = r;
                }
            });
            var text = "ID,Serial,Status,From,To\n";
            for (var dId in map) {
              if (map.hasOwnProperty(dId)) {
                var prefix = map[dId].deviceId + "," + map[dId].deviceSerial + ",";
                var tmp = Service.processStatusLog(map[dId].deviceOnline, map[dId].deviceActivity || [], displayStart, logDurationInDay).barData;
                tmp.days.forEach(function(d){
                  tmp[d].forEach(function(onlineRecord){
                    if (onlineRecord.online) {
                      if (onlineRecord.statusBars.length) {
                        onlineRecord.statusBars.forEach(function(statusRecord){
                          text += prefix + (statusRecord.status ? "On" : "Off") + "," + Service.datetimeFmt(statusRecord.sTime, "yyyy-MM-dd HH:mm:ss") + "," + Service.datetimeFmt(statusRecord.eTime, "yyyy-MM-dd HH:mm:ss") + "\n";
                        });
                      } else {//in case some device have no on/off function
                        text += prefix + "Online," + Service.datetimeFmt(onlineRecord.sTime, "yyyy-MM-dd HH:mm:ss") + "," + Service.datetimeFmt(onlineRecord.eTime, "yyyy-MM-dd HH:mm:ss") + "\n";
                      }
                    } else {
                      text += prefix + "Offline," + Service.datetimeFmt(onlineRecord.sTime, "yyyy-MM-dd HH:mm:ss") + "," + Service.datetimeFmt(onlineRecord.eTime, "yyyy-MM-dd HH:mm:ss") + "\n";
                    }
                  });
                });
                text += "\n";
              }
            }
            for (var dId in activityOnlyMap) {
              if (activityOnlyMap.hasOwnProperty(dId)) {
                var prefix = activityOnlyMap[dId].deviceId + "," + activityOnlyMap[dId].deviceSerial + ",";
                var activities = activityOnlyMap[dId].deviceActivity;
                activities.forEach(function(a) {
                  text += prefix + (a.status ? "On" : "Off") + "," + Service.datetimeFmt(a.time, "yyyy-MM-dd HH:mm:ss") + ",\n";
                });
                text += "\n";
              }
            }

            var element = document.createElement('a');
            element.style.display = 'none';
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', "device-activity(" + name + ").csv");
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            $rootScope.loadingPage--;
            $scope.btnStatus.saving = false;
            $scope.exportDeviceWin.close();
          }, function(err){
              if (err === KEY.ignore)
                  return;
              $rootScope.loadingPage--;
              $scope.btnStatus.saving = false;
              if (err.message) {
                alert(err.message);
              } else {
                alert(Service.translate("error.generalGetDataFail"));
              }
          });
        }
    /* exportDeviceActivity */

    /* floor assign tenant popup */
        $scope.assignTenantWinData = {};
        $scope.assignTenantWinOpt = {
            title: Service.translate("tenant.assignTenant"),
            width: "700px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.buildingDropdown2Opt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "buildingId",
            change: function(){
                var building = Service.getArrItem($scope.buildings, this.value());
                updateGwSelect2(building);
            }
        }
        $scope.zoneDropdownOpt = {
            cascadeFrom: "buildingDropdown2",
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "id",
        }
        function updateGwSelect2(building){
            $scope.gatewaySelect2.setDataSource(new kendo.data.DataSource({
                data: (building ? building.hubs.reduce(function(arr, hub){
                    return arr.concat(hub.gateways);
                }, []) : [])
            }));
        }

        $scope.confirmAssignTenant = function(){
            $scope.btnStatus.saving = true;
            var domain = $scope.parseDomain($scope.assignTenantWinData.domain);
            if ($scope.assignTenantWinData.isNewZone) {
                    caller.call(Api.createFloor($scope.assignTenantWinData.buildingId, domain, $scope.assignTenantWinData.floorName,
                        $scope.assignTenantWinData.fileObject, $scope.assignTenantWinData.gatewayIds, $scope.assignTenantWinData.alwaysOn)).then(function(res){
                        $scope.btnStatus.saving = false;
                        $scope.loadPage();
                        $scope.assignTenantWin.close();
                    }, function(err){
                        if (err === KEY.ignore)
                            return;
                        $scope.btnStatus.saving = false;
                        if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message && err.graphQLErrors[0].message.indexOf("zones_tenant_id_fkey") > -1){
                            alert(Service.translate("error.domainNotFound"));
                        } else {
                            alert(Service.translate("error.generalCreateFloor"));
                        }
                    });
            } else {
                caller.call(Api.assignTenantToFloor($scope.assignTenantWinData.floorId, domain)).then(function(res){
                    $scope.btnStatus.saving = false;
                    $scope.loadPage();
                    $scope.assignTenantWin.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.btnStatus.saving = false;
                    alert(Service.translate("error.generalUpdateFloor"));
                });
            }
        }
        $scope.assignTenant = function(){
            if ($scope.buildings.length) {
                $scope.assignTenantWinData.buildingId = $scope.buildings[0].id;
                updateGwSelect($scope.buildings[0]);    
            } else {
                $scope.assignTenantWinData.buildingId = null;
                updateGwSelect(null);
            }
            $scope.assignTenantWinData.init();

            $scope.assignTenantWinData.domain = "";
            $scope.assignTenantWinData.isNewZone = false;
            $scope.assignTenantWinData.floorName = null;
            $scope.assignTenantWinData.gatewayIds = [];
            $scope.assignTenantWinData.alwaysOn = false;
            $scope.assignTenantWinData.pauseScheduleTime = true;
            setTimeout(function(){
                $scope.assignTenantWin.open().center();
            });
        }
        $scope.isValidAssignTenantInput = function(){
            if (!$scope.isValidDomain($scope.assignTenantWinData.domain) || !$scope.assignTenantWinData.buildingId)
                return false;
            if ($scope.assignTenantWinData.isNewZone) {
                if (!$scope.assignTenantWinData.floorName || !$scope.assignTenantWinData.fileObject)
                    return false; 
            } else {
                if (!$scope.assignTenantWinData.floorId)
                    return false;
            }
            return true;
        }
    /* floor assign tenant popup */

    /* edit tenant popup */
        $scope.editTenantWinData = {};
        $scope.editTenantWinOpt = {
            width: "900px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.createDummyHubGw = function(){
          $scope.btnStatus.saving = true;
            caller.call(Api.createDummyHubGw($scope.editTenantWinData.tenantId)).then(function(res){
                $scope.btnStatus.saving = false;
           }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(err.message);
            });
        }

        $scope.editTenant = function(tenant){
            $scope.editTenantWin.title(Service.translate("tenant.editTenant"));
            $scope.editTenantWinData.name = tenant.name;
            $scope.editTenantWinData.domain = tenant.dns;
            $scope.editTenantWinData.tenantId = tenant.id;
            $scope.editTenantWinData.userpool = tenant.cognitoUserpool;
            $scope.editTenantWinData.timezone = tenant.defaultTimezone;
            $scope.editTenantWinData.init(tenant.logo);
            $scope.editTenantWinData.tep = tenant.enableTep;
            $scope.editTenantWinData.ems = tenant.enableEms;
            $scope.editTenantWinData.tenantCode = tenant.code || '';
            $scope.editTenantWinData.canEditCode = tenant.code == '' || tenant.code == null;
            $scope.editTenantWinData.hasSolar = tenant.hasSolar;
            $scope.editTenantWinData.hasIaq = tenant.hasIaq;
            $scope.editTenantWinData.widget = tenant.widget;
            $scope.editTenantWinData.showApiKey = false;
            $scope.editTenantWinData.apiKey = tenant.apiKey;
            $scope.editTenantWinData.maxExtendHrs = tenant.maxExtensionHours;
            $scope.editTenantWinData.defaultExtendMins = tenant.defaultExtensionMinutes;
            $scope.editTenantWinData.allowSelectOnMap = tenant.enableFloorPlanSelection;
            $scope.editTenantWinData.msConsentLink = tenant.serviceCredentials.length ? Service.getMsConsentLink(tenant.serviceCredentials[0].providerTenantCode) : null;
            setTimeout(function(){
                $scope.editTenantWin.open().center();
            });
        }
        $scope.confirmEditTenant = function(){
            $scope.btnStatus.saving = true;
            caller.call(Api.updateTenant($scope.editTenantWinData.tenantId, $scope.editTenantWinData.name, $scope.editTenantWinData.timezone, $scope.editTenantWinData.fileObject,
                $scope.editTenantWinData.tep, $scope.editTenantWinData.ems, $scope.editTenantWinData.tenantCode, $scope.editTenantWinData.hasIaq, $scope.editTenantWinData.hasSolar,
                $scope.editTenantWinData.maxExtendHrs, $scope.editTenantWinData.defaultExtendMins, $scope.editTenantWinData.allowSelectOnMap, $scope.editTenantWinData.widget), true).then(function(res){
                $scope.btnStatus.saving = false;
                $scope.loadPage();    //call loadPage, dont use response object, since it may return floors from other landlord
                $scope.editTenantWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalUpdateTenant"));
            });
        }

        $scope.deleteTenantWinOpt = {
            title: Service.translate("tenant.popup.deleteTenant"),
            width: "540px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.deleteTenant = function(tenant){
          $scope.deleteTenantData = {
            confirmText: '',
            name: tenant.name,
            id: tenant.id
          }
          setTimeout(function(){
              $scope.deleteTenantWin.open().center();
          });
        }
        $scope.confirmDeleteTenant = function(){
            $scope.btnStatus.saving = true;
            caller.call(Api.deleteTenant($scope.deleteTenantData.id)).then(function(){
                $scope.btnStatus.saving = false;
                $scope.loadPage($scope.gotoTenant);
                $scope.deleteTenantWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalDeleteFail"));
            });
        }

        $scope.isValidUpdateTenantInput = function(){
            if (!$scope.editTenantWinData.timezone)
                return false;
            if (!$scope.editTenantWinData.tep && !$scope.editTenantWinData.ems)
                return false;
            if (!$scope.editTenantWinData.name.trim())
                return false;
            var extendMaxMins = parseInt($scope.editTenantWinData.maxExtendHrs) * 60 || 0;
            var extendDefaultMins = parseInt($scope.editTenantWinData.defaultExtendMins) || 0;
            if (extendMaxMins <= 0 || extendDefaultMins < 5 || extendDefaultMins > extendMaxMins)
                return false;
            if (!$scope.editTenantWinData.tenantCode.trim()){
                return false;
            }
            
            return true;
        }

        $scope.buildingDropdown3Opt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "buildingId",
            change: function(){
                var building = Service.getArrItem($scope.buildings, this.value());
                updateGwSelect3(building);
            }
        }
        function updateGwSelect3(building){
            $scope.gatewaySelect3.setDataSource(new kendo.data.DataSource({
                data: (building ? building.hubs.reduce(function(arr, hub){
                    return arr.concat(hub.gateways);
                }, []) : [])
            }));
        }

        $scope.compLogoData = {};
        $scope.createTenantWinData = {};
        $scope.createTenant = function() {
            if ($scope.buildings.length) {
                $scope.createTenantWinData.buildingId = $scope.buildings[0].id;
                updateGwSelect3($scope.buildings[0]);    
            } else {
                $scope.createTenantWinData.buildingId = null;
                updateGwSelect3(null);
            }
            $scope.createTenantWin.title(Service.translate("button.createTenant"));
            $scope.createTenantWinData.name = '';
            $scope.createTenantWinData.domain = '';
            $scope.createTenantWinData.timezone = $scope.timezones[0];
            $scope.createTenantWinData.maxExtendHrs = KEY.defaultTimerMaxHr;
            $scope.createTenantWinData.defaultExtendMins = KEY.defaultTimerExtendMin;
            $scope.createTenantWinData.allowSelectOnMap = false;
            $scope.createTenantWinData.tep = true;
            $scope.createTenantWinData.ems = false;
            $scope.createTenantWinData.tenantCode = "";
            $scope.createTenantWinData.hasSolar = false;
            $scope.createTenantWinData.hasIaq = false;
            $scope.createTenantWinData.userpool = "";
            $scope.createTenantWinData.widget = {
              airconEnable: false,
              lightingEnable: false,
              greenScoreRatingEnable: false,
              energyConsumptionEnable: false,
              indoorAirQualityEnable: false
            }
            if ($rootScope.isAclRole(APIKEY.aclRole.super)) {
                $scope.createTenantWinData.createZone = false;
            } else {
                $scope.createTenantWinData.createZone = true;
            }
            $scope.compLogoData.init();
            $scope.createTenantWinData.init();
            $scope.createTenantWinData.floorName = '';
            $scope.createTenantWinData.alwaysOn = false;
            $scope.createTenantWinData.gatewayIds = [];

            setTimeout(function(){
                $scope.createTenantWin.open().center();
            });
        }
        $scope.confirmCreateTenant = function(){
            $scope.btnStatus.saving = true;
            var userpool = $scope.createTenantWinData.userpool ? $scope.createTenantWinData.userpool : null;
            if ($scope.createTenantWinData.createZone){
                caller.call(Api.createTenantAndFloor($scope.createTenantWinData.buildingId, $scope.createTenantWinData.name, $scope.createTenantWinData.timezone,
                    $scope.parseDomain($scope.createTenantWinData.domain), $scope.createTenantWinData.maxExtendHrs, $scope.createTenantWinData.defaultExtendMins,
                    $scope.createTenantWinData.allowSelectOnMap, $scope.createTenantWinData.widget, $scope.createTenantWinData.floorName, $scope.createTenantWinData.fileObject,
                    $scope.createTenantWinData.gatewayIds, $scope.createTenantWinData.alwaysOn, $scope.createTenantWinData.ems, $scope.compLogoData.fileObject,
                    $scope.createTenantWinData.hasIaq, $scope.createTenantWinData.hasSolar, $scope.createTenantWinData.tenantCode), true).then(function(res){
                    $scope.btnStatus.saving = false;
                    $scope.loadPage();
                    $scope.createTenantWin.close();
                }, function(err){
                    if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message && err.graphQLErrors[0].message.indexOf("tenants_pkey") > -1){
                        alert(Service.translate("error.duplicatedDomain"));
                    } else {
                        alert(Service.translate("error.generalCreateTenantAndFloor"));
                    }
                    $scope.btnStatus.saving = false;
                });
            } else {
                caller.call(Api.createTenant($scope.createTenantWinData.name, $scope.createTenantWinData.timezone, $scope.parseDomain($scope.createTenantWinData.domain), userpool,
                    $scope.compLogoData.fileObject, $scope.createTenantWinData.tep, $scope.createTenantWinData.ems, $scope.createTenantWinData.tenantCode,
                    $scope.createTenantWinData.hasIaq, $scope.createTenantWinData.hasSolar, $scope.createTenantWinData.maxExtendHrs,
                    $scope.createTenantWinData.defaultExtendMins, $scope.createTenantWinData.allowSelectOnMap, $scope.createTenantWinData.widget), true).then(function(res){
                    $scope.btnStatus.saving = false;
                    $scope.loadPage();
                    $scope.createTenantWin.close();
                }, function(err){
                    if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message && err.graphQLErrors[0].message.indexOf("tenants_pkey") > -1){
                        alert(Service.translate("error.duplicatedDomain"));
                    } else {
                        alert(Service.translate("error.generalCreateTenant"));
                    }
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.isValidCreateTenantInput = function(){
            if (!$scope.createTenantWinData.timezone)
                return false;
            if (!$scope.createTenantWinData.tep && !$scope.createTenantWinData.ems)
                return false;
            if (!$scope.isValidDomain($scope.createTenantWinData.domain) || !$scope.createTenantWinData.name.trim())
                return false;
            var extendMaxMins = parseInt($scope.createTenantWinData.maxExtendHrs) * 60 || 0;
            var extendDefaultMins = parseInt($scope.createTenantWinData.defaultExtendMins) || 0;
            if (extendMaxMins <= 0 || extendDefaultMins < 5 || extendDefaultMins > extendMaxMins)
                return false;
            if ($scope.createTenantWinData.createZone) {
                if (!$scope.createTenantWinData.buildingId || !$scope.createTenantWinData.floorName || !$scope.createTenantWinData.fileObject)
                    return false;
            }
            if (!$scope.createTenantWinData.tenantCode.trim()){
                return false;
            }
            
            return true;
        }
    /* edit tenant popup */

    /* manage tenant login popup */
        $scope.labelMap = {};
        $scope.labelMap[APIKEY.provider.google] = Service.translate("tenant.popup.googleId");
        $scope.labelMap[APIKEY.provider.microsoft] = Service.translate("tenant.popup.microsoftId");
        $scope.labelMap.domain = Service.translate("tenant.popup.domain");
        
        $scope.providerDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("tenant.popup.google"),
                value: APIKEY.provider.google,
            }, {
                text: Service.translate("tenant.popup.microsoft"),
                value: APIKEY.provider.microsoft,
            }],
            dataTextField: "text",
            dataValueField: "value"
        }

        $scope.manageLoginWinData = {};
        $scope.manageLoginWinOpt = {
            title: Service.translate("button.manageLogin"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }

        function processAndPushOAuth(oauth){
            if (oauth.provider === APIKEY.provider.email){
                $scope.manageLoginWinData.allowedDomains.push(oauth);
            } else {
                var showCount = Math.min(5, Math.floor(oauth.code.length / 2));
                var tmp = '';
                for (var i=0; i<oauth.code.length-showCount; i++){
                    tmp += 'x';
                }
                oauth.maskedCode = oauth.code.substring(0, showCount) + tmp;
                $scope.manageLoginWinData.oauths.push(oauth);
            }
        }
        $scope.manageTenantLogin = function(tenant){
            $scope.manageLoginWinData.tenant = tenant;
            $scope.manageLoginWinData.oauths = [];
            $scope.manageLoginWinData.allowedDomains = [];
            tenant.oauths.forEach(processAndPushOAuth);

            $scope.manageLoginWinData.provider = APIKEY.provider.microsoft;
            $scope.manageLoginWinData.domain = "";
            $scope.manageLoginWinData.oauth = "";

            setTimeout(function(){
                $scope.manageLoginWin.open().center();
            });
        }

        $scope.saveDomain = function(){
            $scope.btnStatus.savingDomain = true;
            var domain = $scope.parseDomain($scope.manageLoginWinData.domain);
            caller.call(Api.addOAuthBinding($scope.manageLoginWinData.tenant.id, domain, APIKEY.provider.email)).then(function(tenant){
                $scope.manageLoginWinData.tenant.oauths = tenant.oauths;
                $scope.manageLoginWinData.domain = "";
                processAndPushOAuth({
                    provider: APIKEY.provider.email,
                    code: domain
                });
                $scope.btnStatus.savingDomain = false;
                $scope.manageLoginWin.center();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.savingDomain = false;
                alert(Service.translate("error.generalSaveFail"));
            });
        }
        $scope.saveOAuth = function(){
            $scope.btnStatus.savingOAuth = true;
            var code = ($scope.manageLoginWinData.oauth || '').trim();
            var provider = $scope.manageLoginWinData.provider;
            caller.call(Api.addOAuthBinding($scope.manageLoginWinData.tenant.id, code, provider)).then(function(tenant){
                $scope.manageLoginWinData.tenant.oauths = tenant.oauths;
                $scope.manageLoginWinData.oauth = "";
                processAndPushOAuth({
                    provider: provider,
                    code: code
                });
                $scope.btnStatus.savingOAuth = false;
                $scope.manageLoginWin.center();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.savingOAuth = false;
                alert(Service.translate("error.generalSaveFail"));
            });
        }
        $scope.deleteDomain = function(code){
            $rootScope.deletePopup.show("tenant.popup.deleteDomainTitle", "tenant.popup.deleteDomainDesc", code, function(){
                caller.call(Api.removeOAuthBinding($scope.manageLoginWinData.tenant.id, code)).then(function(tenant){
                    $scope.manageLoginWinData.tenant.oauths = tenant.oauths;
                    Service.deleteArrItem($scope.manageLoginWinData.allowedDomains, code, "code");
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalDeleteFail"));
                });
            });
        }
        $scope.deleteOAuth = function(item){
            $rootScope.deletePopup.show("tenant.popup.deleteOAuthTitle", "tenant.popup.deleteOAuthDesc", item.maskedCode, function(){
                caller.call(Api.removeOAuthBinding($scope.manageLoginWinData.tenant.id, item.code)).then(function(tenant){
                    $scope.manageLoginWinData.tenant.oauths = tenant.oauths;
                    var tmp = $scope.manageLoginWinData.oauths;
                    for (var i=0; i<tmp.length; i++){
                        if (tmp[i].code === item.code && tmp[i].provider === item.provider){
                            tmp.splice(i, 1);
                            break;
                        }
                    }
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalDeleteFail"));
                });
            });
        }
    /* manage tenant login popup */

        $scope.$on('$destroy', function() {
            console.log("tenantController destroy");
            caller.cancel();
        });
    }
})();